<template>
  <div>
    <h1>{{ $t("register.success.title") }}</h1>
    <p v-if="$store.state.save.success">
      {{
        $t("register.success.info", {
          email:
            $store.state.save.userData.email ||
            $store.state.save.registredUser.email
        })
      }}
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
